import * as React from 'react';
import { LocalStorageManager } from './localStorageManager';
import { Options } from './types';
import { createAuthorizeUrl } from './createAuthorizeUrl';

interface NoValidToken {
  name: 'no-valid-token';
}

interface ValidityCheck {
  name: 'validity-check';
  token: string;
}

interface HasValidToken {
  name: 'has-valid-token';
  token: string;
}

type Status = NoValidToken | ValidityCheck | HasValidToken;

export interface OauthSenderProps {
  children: (status: Status) => React.ReactNode;
  localStorageManager: LocalStorageManager;
  onStatusChange?: (status: Status) => void;
  options: Options;
}

interface OauthSenderState {
  status: Status;
}

function initStatus(localStorageManager: LocalStorageManager): Status {
  const savedToken = localStorageManager.getTokenFromLocalStorage();
  if (savedToken) {
    return { name: 'validity-check', token: savedToken };
  }
  return { name: 'no-valid-token' };
}

export class OauthSender extends React.Component<
  OauthSenderProps,
  OauthSenderState
> {
  state = {
    status: initStatus(this.props.localStorageManager),
  };

  ignoreUpdate = false;

  componentDidMount() {
    this.runEffect();
    this.statusChangeCallback();
  }

  componentDidUpdate(_: OauthSenderProps, prevState: OauthSenderState) {
    const status = this.state.status;

    if (status !== prevState.status) {
      this.statusChangeCallback();
      this.runEffect();
    }
  }

  componentWillUnmount() {
    this.ignoreUpdate = true;
  }

  statusChangeCallback = () => {
    this.props.onStatusChange && this.props.onStatusChange(this.state.status);
  };

  runEffect = () => {
    const { options, localStorageManager } = this.props;
    const status = this.state.status;

    if (status.name === 'validity-check') {
      this.validateToken(status.token);
    }

    if (status.name === 'no-valid-token') {
      localStorageManager.removeTokenFromLocalStorage();
      if (options.redirectToAuthorizeUrl) {
        window.location.assign(createAuthorizeUrl(options)());
      }
    }
  };

  validateToken = async (token: string) => {
    const { options } = this.props;

    try {
      const res = await fetch(
        `${options.apiUrl}/accounts/me?access_token=${token}&fields=id`
      );
      if (res.status !== 200) {
        throw new Error('Failed to get user.');
      }
      if (this.ignoreUpdate) return;
      this.setState({ status: { name: 'has-valid-token', token } });
    } catch (e) {
      if (this.ignoreUpdate) return;
      this.setState({ status: { name: 'no-valid-token' } });
    }
  };

  render() {
    const { children } = this.props;
    const { status } = this.state;
    return <>{children(status)}</>;
  }
}
