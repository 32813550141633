import { Options } from './types';

export const createLocalStorageManager = (options: Options) => {
  const key = `@logiscool/${options.clientId}/token`;

  function getTokenFromLocalStorage() {
    return window.localStorage.getItem(key);
  }

  function saveTokenToLocalStorage(token: string) {
    return window.localStorage.setItem(key, token);
  }

  function removeTokenFromLocalStorage() {
    return window.localStorage.removeItem(key);
  }

  return {
    getTokenFromLocalStorage,
    saveTokenToLocalStorage,
    removeTokenFromLocalStorage,
  };
};

export type LocalStorageManager = ReturnType<typeof createLocalStorageManager>;
