import * as React from 'react';
import { LocalStorageManager } from './localStorageManager';
import { Options } from './types';

interface FetchingToken {
  name: 'fetching-token';
}

interface Done {
  name: 'done';
  token: string;
  origin: string;
}

interface Error {
  name: 'error';
}

type Status = FetchingToken | Done | Error;

export interface OauthReceiverProps {
  options: Options;
  localStorageManager: LocalStorageManager;
  children: (status: Status) => React.ReactNode;
  onStatusChange?: (status: Status) => void;
}

interface OauthReceiverState {
  status: Status;
}

const initialStatus: Status = {
  name: 'fetching-token',
};

export class OauthReceiver extends React.Component<
  OauthReceiverProps,
  OauthReceiverState
> {
  state = {
    status: initialStatus,
  };

  ignoreUpdate = false;

  componentDidMount() {
    this.statusChangeCallback();
    this.runEffect();
  }

  componentWillUnmount() {
    this.ignoreUpdate = true;
  }

  componentDidUpdate(_: OauthReceiverProps, prevState: OauthReceiverState) {
    if (prevState.status !== this.state.status) {
      this.statusChangeCallback();
      this.runEffect();
    }
  }

  statusChangeCallback = () => {
    this.props.onStatusChange && this.props.onStatusChange(this.state.status);
  };

  runEffect = () => {
    if (this.state.status.name === 'fetching-token') {
      this.exhangeAndSaveToken();
    }
  };

  exhangeAndSaveToken = async () => {
    const { options, localStorageManager } = this.props;

    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    let origin = '/';
    if (state) {
      try {
        origin = JSON.parse(decodeURIComponent(state)).origin;
      } catch (e) {}
    }

    try {
      if (!code) {
        throw new Error('Missing code.');
      }
      const token = await exchangeCodeForToken(options, code);
      if (this.ignoreUpdate) return;
      localStorageManager.saveTokenToLocalStorage(token);
      this.setState({
        status: {
          name: 'done',
          token,
          origin,
        },
      });
    } catch (e) {
      if (this.ignoreUpdate) return;
      this.setState({ status: { name: 'error' } });
    }
  };

  render() {
    const { children } = this.props;
    const { status } = this.state;
    return <>{children(status)}</>;
  }
}

async function exchangeCodeForToken(options: Options, code: string) {
  const res = await fetch(`${options.oauthUrl}/api/oauth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code: code,
      grant_type: 'authorization_code',
      client_id: options.clientId,
      client_secret: options.clientSecret,
      redirect_uri: options.redirectUrl,
    }),
  });
  const json = await res.json();
  return json.access_token as string;
}
