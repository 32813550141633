import * as React from 'react';
import {
  OauthReceiver as BaseOauthReceiver,
  OauthReceiverProps,
} from './OauthReceiver';
import {
  OauthSender as BaseOauthSender,
  OauthSenderProps,
} from './OauthSender';
import { createLocalStorageManager } from './localStorageManager';
import { Options } from './types';
import { createAuthorizeUrl } from './createAuthorizeUrl';

const defaultOptions: Partial<Options> = {
  redirectToAuthorizeUrl: true,
};

export function createOauthClient(options: Options) {
  const mergedOptions = { ...defaultOptions, ...options };
  const localStorageManager = createLocalStorageManager(options);

  const logout = () => {
    localStorageManager.removeTokenFromLocalStorage();
    window.location.assign(
      `${mergedOptions.oauthUrl}/api/auth/logout?redirect=${window.location.origin}`
    );
  };

  const OauthReceiver = (
    props: Omit<OauthReceiverProps, 'options' | 'localStorageManager'>
  ) => {
    return (
      <BaseOauthReceiver
        options={mergedOptions}
        onStatusChange={props.onStatusChange}
        localStorageManager={localStorageManager}
      >
        {props.children}
      </BaseOauthReceiver>
    );
  };

  const OauthSender = (
    props: Omit<OauthSenderProps, 'options' | 'localStorageManager'>
  ) => {
    return (
      <BaseOauthSender
        options={mergedOptions}
        onStatusChange={props.onStatusChange}
        localStorageManager={localStorageManager}
      >
        {props.children}
      </BaseOauthSender>
    );
  };

  return {
    /**
     * Component responsibe for exchanging an authorization code for an access token.
     */
    OauthReceiver,

    /**
     * Component responsible for retrieving and validating the access token stored in `localStorage`.
     * Depending on the `redirectToAuthorizeUrl` option, it'll also redirectto the authorization URL,
     * if there's no locally stored, valid token.
     */
    OauthSender,

    /**
     * Removes access token from `localStorage` and redirects to the logout URL of the API.
     */
    logout,

    /**
     * Creates an authorize URL.
     *
     *  @param origin - Will be passed with the URL as state. Defaults to`window.location.pathname + window.location.search`
     */
    createAuthorizeUrl: createAuthorizeUrl(options),

    localStorageManager,
  };
}
