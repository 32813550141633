import { Options } from './types';

export const createAuthorizeUrl = (options: Options) => (origin?: string) => {
    const defaultOrigin = encodeURIComponent(
      window.location.pathname + window.location.search
    );
  
    const searchParams = new URLSearchParams();
    searchParams.set('response_type', 'code');
    searchParams.set('client_id', options.clientId);
    searchParams.set('redirect_uri', options.redirectUrl);
    searchParams.set(
      'state',
      JSON.stringify({ origin: origin || defaultOrigin })
    );
  
    return `${options.oauthUrl}/api/oauth/authorize?${searchParams}`;
  };